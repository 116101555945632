
import { defineComponent, ref } from 'vue'
import MyHeader from '@/components/MyHeader.vue'
import CommonFooter from '@/components/CommonFooter.vue'

export default defineComponent({
  name: 'About',
  components: {
    MyHeader,
    CommonFooter
  }
})
